import React from 'react'
import PropTypes from 'prop-types'
import FieldWrap from './FieldWrap'

const InputField = ({ name, label, required, ...rest }) => {
  return (
    <FieldWrap name={name} label={label} required={required}>
      {field => {
        return (
          <input
            {...field}
            value={field.value || ''}
            id={`field-control-${name}`}
            className="field-control"
            {...rest}
          />
        )
      }}
    </FieldWrap>
  )
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default InputField
