import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import classnames from 'classnames'
import Icon from 'shared/ui/Icon'

// This custom field always thinks of checkboxes as being arrays of values even if there
// is just one.

const Checkbox = ({ label, name, value, disabled }) => (
  <Field name={name}>
    {({ field, form }) => {
      const values = Array.isArray(field.value) ? field.value : []
      const checked = values.includes(value)

      return (
        <div className={classnames('checkbox-field-control', { disabled })}>
          <label className="icon-text">
            <input
              type="checkbox"
              name={name}
              checked={checked}
              disabled={disabled}
              onChange={() => {
                let nextValue
                if (checked) {
                  nextValue = values.filter(v => v !== value)
                  form.setFieldValue(name, nextValue)
                } else {
                  nextValue = values.concat(value)
                  form.setFieldValue(name, nextValue)
                }
              }}
            />
            <Icon color={disabled ? 'gray' : 'blue'} icon={checked ? 'checkbox-checked' : 'checkbox-unchecked'} />
            <span>{label}</span>
          </label>
        </div>
      )
    }}
  </Field>
)

Checkbox.defaultProps = {
  disabled: false,
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default Checkbox
