import React from 'react'
import PropTypes from 'prop-types'
import FieldWrap from './FieldWrap'

const SelectField = ({ children, name, label, required, ...rest }) => {
  return (
    <FieldWrap name={name} label={label} required={required}>
      {field => {
        return (
          <select {...field} value={field.value || ''} id={`field-control-${name}`} className="field-control" {...rest}>
            {children}
          </select>
        )
      }}
    </FieldWrap>
  )
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default SelectField
