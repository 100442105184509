import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const SubmitButton = props => {
  const { disabled, submitting, className } = props
  const content = submitting ? 'Submitting...' : props.children || 'Submit'

  return (
    <button type="submit" disabled={disabled || submitting} className={classnames('button', className, { submitting })}>
      {content}
    </button>
  )
}

SubmitButton.defaultProps = {
  disabled: false,
  submitting: false
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  className: PropTypes.string
}

export default SubmitButton
