import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { componentTypeIsSame } from 'shared/utils/client/reactType'
import useNotice from 'shared/utils/client/useNotice'
import InlineNotice from 'shared/ui/InlineNotice'
import SubmitButton from './SubmitButton'
import CommonFormFooter from './CommonFormFooter'

const CommonForm = ({ children, onSubmit, validate, submitLabel, initialValues, resetOnSuccess }) => {
  const mounted = useRef(true)
  useEffect(() => {
    return () => (mounted.current = false)
  }, [])

  const { noticeMessage, noticeType, setNotice, clearNotice } = useNotice()

  const _onSubmit = (values, form) => {
    clearNotice()
    return onSubmit(values, form)
      .then(message => {
        if (!mounted.current) return
        form.setSubmitting(false)
        if (typeof message === 'string') setNotice(message, 'success', 10000)
        if (resetOnSuccess) form.resetForm()
      })
      .catch(({ message }) => {
        if (!mounted.current) return
        form.setSubmitting(false)
        if (typeof message === 'string') setNotice(message, 'error')
      })
  }

  let useDefaultFooter = true

  return (
    <Formik
      onSubmit={_onSubmit}
      validate={validate}
      // https://github.com/jaredpalmer/formik/pull/1410
      isInitialValid={!!initialValues}
      initialValues={initialValues}
      enableReinitialize
      render={form => {
        const { isValid, submitCount, isSubmitting } = form

        let displayNoticeMessage = noticeMessage
        let displayNoticeType = noticeType
        if (submitCount > 0 && !isValid && !noticeMessage) {
          displayNoticeMessage = 'Some fields are not valid.'
          displayNoticeType = 'error'
        }

        return (
          <form onSubmit={form.handleSubmit} className="common-form spacing" noValidate method="POST">
            {displayNoticeMessage && (
              <InlineNotice type={displayNoticeType} icon={noticeType === 'error' ? 'alert-circle' : null}>
                <p>{displayNoticeMessage}</p>
              </InlineNotice>
            )}

            {/* See if <CommonFormFooter /> was used, if so use it. Otherwise we'll make a default footer */}
            {React.Children.map(children, child => {
              if (componentTypeIsSame(CommonFormFooter, child.type)) {
                useDefaultFooter = false
                return React.cloneElement(child, { form })
              } else {
                return child
              }
            })}

            {useDefaultFooter && (
              <footer>
                <SubmitButton submitting={isSubmitting}>{submitLabel}</SubmitButton>
              </footer>
            )}
          </form>
        )
      }}
    />
  )
}

CommonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  noticeMessage: PropTypes.string,
  noticeType: PropTypes.string,
}

export default CommonForm
