import React from 'react'
import PropTypes from 'prop-types'
import houseSchema from 'shared/house-schema/all'
import { CommonForm, InputField } from 'shared/ui/form/all'

const schema = houseSchema.clone(['firstName', 'lastName', 'email', 'password'])

const RegistrationForm = ({ onSubmit, submitLabel }) => (
  <CommonForm onSubmit={onSubmit} validate={schema.validate} submitLabel={submitLabel}>
    <InputField label="First Name" name="firstName" required />
    <InputField label="Last Name" name="lastName" required />
    <InputField label="Email" name="email" type="email" required />
    <InputField label="Password" name="password" type="password" required />
  </CommonForm>
)

RegistrationForm.defaultProps = {
  submitLabel: 'Signup',
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
}

export default RegistrationForm
