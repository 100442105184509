import axios from 'axios'
import globalConfig from 'shared/config/common/globalConfig'

// Instance
const xhr = axios.create({
  baseURL: `${globalConfig.api.protocol}://${globalConfig.api.host}`,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  withCredentials: true
})

// Middleware
xhr.interceptors.response.use(response => {
  return response.data
}, error => {
  if (!error.response) return Promise.reject(error.message)
  const { status, data } = error.response

  return Promise.reject({ status, message: data })
})

export default xhr
