import { useState, useRef, useEffect } from 'react'

const initialNotice = {
  noticeMessage: '',
  noticeType: '',
}

const useNotice = () => {
  const mounted = useRef(true)
  useEffect(() => {
    return () => (mounted.current = false)
  }, [])

  const [notice, _setNotice] = useState(initialNotice)
  const timeoutRef = useRef()

  const setNotice = (noticeMessage, noticeType = null, ms = 0) => {
    _setNotice({ noticeMessage, noticeType })
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    ms = parseInt(ms)
    if (ms > 0) {
      timeoutRef.current = setTimeout(() => {
        if (!mounted.current) return
        clearNotice()
      }, ms)
    }
  }

  const clearNotice = () => {
    _setNotice(initialNotice)
  }

  return {
    ...notice,
    setNotice,
    clearNotice,
  }
}

export default useNotice
