import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import classnames from 'classnames'

const FieldWrap = ({ children, name, label, required }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const { submitCount, touched, errors } = form
        const errorMessage = (submitCount > 0 || touched[name]) && errors[name] ? errors[name] : ''

        return (
          <div className={classnames('field-wrap', { invalid: errorMessage.length, required })}>
            {label && <label htmlFor={`field-control-${name}`}>{label}</label>}
            <div className="field-wrap-body">
              <Tooltip disabled={!errorMessage.length} title={errorMessage || ''} position="bottom" arrow distance={20}>
                {children(field)}
              </Tooltip>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

FieldWrap.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default FieldWrap
