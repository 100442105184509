import React from 'react'
import Link, { push } from 'gatsby-link'
import { useMediaLayout } from 'use-media'
import { Columns, Column } from 'react-flex-columns'
import globalConfig from '../shared/config/common/globalConfig'
import api from '../api/all'
import DocumentLayout from '../DocumentLayout'
import Centered from '../Centered'
import RegistrationForm from '../shared/ui/RegistrationForm'

function Signup() {
  const stack = useMediaLayout({ maxWidth: '980px' })

  function onSubmit(values) {
    return api.users.register(values).then(() => push('/signup-complete'))
  }

  return (
    <DocumentLayout className="subpage-blue with-logos">
      <Centered>
        <br />
        <Columns gutterSize={3} stack={stack}>
          <Column size={27} className="spacing">
            <h1>Sign up</h1>
            <p>
              Be sure to verify your email upon registration. If you already have an account:{' '}
              <a href={`${globalConfig.app.protocol}://${globalConfig.app.host}/auth/login`}>Login</a>
            </p>
            <hr />
            <p className="text-small">
              By signing up, you agree to our
              <br />
              <Link to="/legal/terms-of-service">Terms of Service</Link> and{' '}
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </p>
          </Column>
          <Column flex>
            <RegistrationForm onSubmit={onSubmit} />
          </Column>
        </Columns>
      </Centered>
    </DocumentLayout>
  )
}

export default Signup
