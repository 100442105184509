import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import classnames from 'classnames'
import { Columns, Column } from 'react-flex-columns'
import Icon from 'shared/ui/Icon'
import theme from 'shared/styles/theme'

const inlineNoticeStyles = css`
  padding: 0.6em;
  border-radius: 4px;

  &.inline-notice-type-default {
    background-color: ${theme.lightBlue};
    color: ${theme.darkBlue};
    border: 1px solid #d0e3e9;
    .icon {
      fill: #a8ccd8;
    }
    a {
      color: ${theme.brightBlue};
      text-decoration: underline;
    }
  }

  &.inline-notice-type-error {
    background-color: ${theme.warn};
    color: #fff;
    border-color: transparent;
    a {
      color: ${theme.lightPink};
      text-decoration: underline;
    }
  }

  &.inline-notice-type-success {
    background-color: ${theme.green};
    color: #fff;
    border-color: transparent;
    a {
      color: ${theme.lightGreen};
      text-decoration: underline;
    }
  }

  &.inline-notice-type-warn {
    border: 1px solid ${theme.warn};
    background-color: #fff;
  }
`

const InlineNotice = ({ children, active = true, icon, type }) => {
  if (!children || !active) return null
  const noticeType = `inline-notice-type-${type || 'default'}`

  return (
    <div css={inlineNoticeStyles} className={classnames('spacing', noticeType)}>
      {icon ? (
        <Columns gutters middle>
          <Column size={2}>
            <Icon icon={icon} size={2} />
          </Column>
          <Column flex className="spacing">
            {children}
          </Column>
        </Columns>
      ) : (
        children
      )}
    </div>
  )
}

InlineNotice.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['default', 'warn', 'error', 'success']),
}

export default InlineNotice
