import { Is, Schema, errorFormat } from 'house-rules'

// Base Rules
export const id = Is.numeric().positive().integer().required()
export const name = Is.string().ascii().maxLength(50).required()
export const title = Is.string().ascii().maxLength(50).required()
export const password = Is.string().minLength(6).maxLength(100).required()
export const uuid = Is.string().regex(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i).required()

const schema = new Schema({
  // Accounts
  accountId: id.label('Account ID'),
  companyName: Is.string().maxLength(50).required(),

  // Users
  userId: id.label('User ID'),
  receiptUserId: id.label('User ID'),
  firstName: name.maxLength(30).label('First Name'),
  lastName: name.maxLength(30).label('Last Name'),

  // Authentication
  email: Is.string().email().required(),
  password: password,
  newPassword: password,
  verificationCode: uuid,

  // Pagination
  page: Is.numeric().positive().integer(),
  resultsPerPage: Is.numeric().positive().integer(),

  // Tests, Questions, and Tags
  testId: id.label('Test ID'),
  testName: name.maxLength(30).label('Test Name'),
  questionId: id.label('Question ID'),
  questionType: Is.any().in(['multipleChoice', 'trueFalse']).required(),
  active: Is.any().in(['true', 'false']).required(),
  timeLimit: Is.numeric().integer().min(1).max(480, 'The largest value allowed is 480 minutes (8 Hours)').required(),
  pointsAvailable: Is.numeric().integer().min(1).max(5).required(),
  difficulty: Is.numeric().min(0).max(1).required(),
  tagName: Is.string().maxLength(30).ascii().required(),
  contactEmail: Is.string().email().required(),

  // Test Invite and Test Sessions
  testInviteId: id.label('Test Invite ID'),
  testInviteAuthCode: Is.string().ascii().maxLength(8).label('Test Invite Auth Code').required(),
  testSessionAuthCode: uuid.label('Test Session Auth Code'),
  linkName: Is.string().ascii().maxLength(40).required(),

  // Groups and Members
  groupId: id.label('Test ID'),
  groupName: name.label('Group Name'),
  memberId: id.label('Member ID'),
  memberAuthCode: Is.string().ascii().maxLength(8).label('Member Auth Code'),

  // Misc
  testReviewLinkId: id.label('Test Review Link ID'),
  userNoticeType: Is.string().required(),
  emailLogId: id.label('Email Log ID'),
  testTakerId: id.label('Test Taker ID'),

  // Marketplace
  marketplaceTestDescription: Is.string().ascii().maxLength(5000).required(),

  // Payments and Billing
  paymentMethodId: id.label('Payment Method ID'),
  reportedPrice: Is.numeric().positive().integer().required(),
}).onError(errorFormat.combineWithLabels)

export default schema
